@font-face {
  font-family: 'ChiswickGrotesqueLebara';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/resources/fonts/ChiswickGrotesqueLebara-Bold-Web.woff') format('woff');
}

@font-face {
  font-family: 'ChiswickGrotesqueLebara';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/resources/fonts/ChiswickGrotesqueLebara-Semibold-Web.woff') format('woff');
}

@font-face {
  font-family: 'ChiswickGrotesqueLebara';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/resources/fonts/ChiswickGrotesqueLebara-Regular-Web.woff') format('woff');
}

@font-face {
  font-family: 'ChiswickGrotesqueLebara';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/resources/fonts/ChiswickGrotesqueLebara-Light-Web.woff') format('woff');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/resources/fonts/Nunito-regular.woff2') format('woff2'),
    url('/resources/fonts/Nunito-regular.woff') format('woff');
}
