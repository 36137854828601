body {
  .crossed-out {
    text-decoration: none;
    position: relative;
    font-weight: normal;
  }
  .crossed-out::before,
  strike::before {
    top: 50%;
    opacity: 0.7;
    content: '';
    width: 110%;
    background: var(--color-red);
    position: absolute;
    height: 0.1em;
    border-radius: 0.1em;
    left: -5%;
    white-space: nowrap;
    display: block;
    transform: rotate(-15deg);
    position: absolute;
  }
}
