:root {
  --color-background-info-dark: var(--blue-200);
  --color-background-info-ligh: var(--blue-50);
  --color-background-negative-dark: var(--red-100);
  --color-background-negative-ligh: var(--red-50);
  --color-background-positive-dark: var(--green-200);
  --color-background-positive-ligh: var(--green-50);
  --color-background-primary: var(--neutral-50);
  --color-background-primary-inverse: var(--neutral-800);
  --color-background-secondary: var(--neutral-100);
  --color-background-secondary-inverse: var(--neutral-500);
  --color-background-tertiary: var(--neutral-300);
  --color-background-warning-dark: var(--yellow-300);
  --color-background-warning-ligh: var(--yellow-50);
  --color-border-info: var(--blue-300);
  --color-border-negative: var(--red-400);
  --color-border-positive: var(--green-400);
  --color-border-primary: var(--neutral-400);
  --color-border-secondary: var(--neutral-500);
  --color-border-warning: var(--yellow-500);
  --color-link-active: var(--blue-700);
  --color-link-default: var(--blue-400);
  --color-link-hover: var(--blue-500);
  --color-primary-dark: var(--azure-700);
  --color-primary-light: linear-gradient(
    90deg,
    var(--color-primary-light-from) 33.95% var(--color-primary-light-to) 111.2%
  );
  --color-primary-default: linear-gradient(var(--color-blue), var(--color-blue));
  --color-primary-default-from: var(--azure-400);
  --color-primary-default-to: var(--azure-500);
  --color-primary-light-from: var(--azure-50);
  --color-primary-light-to: var(--azure-100);
  --color-secondary-dark: var(--pink-700);
  --color-secondary-default: var(--pink-500);
  --color-secondary-light: var(--pink-50);
  --color-skeleton-foreground-from: var(--neutral-300);
  --color-skeleton-foreground-to: var(--neutral-400);
  --color-tertiary-dark: var(--blue-800);
  --color-tertiary-default: var(--blue-900);
  --color-tertiary-light: var(--indigo-50);
  --color-text-info: var(--blue-700);
  --color-text-negative: var(--red-800);
  --color-text-placeholder: var(--neutral-300);
  --color-text-positive: var(--green-800);
  --color-text-primary: var(--neutral-800);
  --color-text-primary-inverse: var(--neutral-50);
  --color-text-secondary: var(--neutral-600);
  --color-text-secondary-inverse: var(--neutral-400);
  --color-text-warning: var(--yellow-800);
}
