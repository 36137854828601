.button {
  display: inline-block;
  text-decoration: none;
  color: white;
  background-color: var(--color-primary);
  border: none;
  padding: 0.8em 2em;
  font-weight: 600;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  position: relative;

  &.primary-gradient {
    background: var(--color-primary-default);
  }

  &.secondary {
    border: solid 1px var(--color-primary);
    background-color: var(--color-gray-7);
    color: var(--color-dark-gray);
    border: 2px solid var(--color-primary);
  }
  &.primary-gradient {
    background: var(--color-primary-default);
  }
  &:not(:disabled):before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--button-border-radius);
    z-index: -1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &.size-sm {
    font-size: 0.875em;
  }

  &.size-2 {
    font-size: 1em;
  }

  &.size-3 {
    font-size: 1.2em;
  }

  &.full-width {
    width: 100%;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &.pulse {
    animation: button-pulse 2s ease infinite;
  }

  &.featured {
    background-color: var(--color-featured);
    &.pulse {
      animation: featured-button-pulse 2s ease infinite;
    }
  }
  &-transparent {
    background-color: transparent;
    border: 2px solid white;
    &-gray {
      background-color: transparent;
      border: 2px solid var(--color-gray-opacity);
    }
    &-card-list {
      background-color: transparent;
      border: 2px solid var(--color-button-card-list);
    }
  }
}

@keyframes button-pulse {
  0% {
    box-shadow: 0 0 0 0 var(--color-primary);
  }
  100% {
    box-shadow: 0 0 0 0.5em transparent;
  }
}

@keyframes featured-button-pulse {
  0% {
    box-shadow: 0 0 0 0 var(--color-featured);
  }
  100% {
    box-shadow: 0 0 0 0.5em transparent;
  }
}
