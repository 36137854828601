/* You can add global styles to this file, and also import other style files */

@use './fonts';
@use '../../../../shared/src/lib/styles/buttons';
@use '../../../../shared/src/lib/styles/crossed-out';

// Sakura
@use '../../../../shared/src/lib/styles/sakura-global-tokens';
@use './sakura-tokens-lebara';
@use '../../../../shared/src/lib/styles/material-theme-variables';

// Todo - to remove the following variables and point to Sakura
//ROOT STYLES
:root {
  font-family: 'ChiswickGrotesqueLebara';

  --mdc-outlined-text-field-container-shape: 0.5rem;
  --mdc-icon-button-icon-size: 18px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-form-field-container-height: 54px;
  --mat-dialog-container-max-width: none;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-form-field-subscript-text-size: 0.875rem;
  --mdc-fab-container-shape: 9999px;

  // Moved sakura tokens

  --color-green-400: #00cf9d;
  --color-green-400-2: rgba(0, 207, 157, 0.2);

  --color-yellow-50: #fff7e1;
  --color-yellow-500: #ffca47;
  --color-yellow-500-2: rgba(255, 202, 71, 0.2);

  --color-blue-300: #68c9e8;
  --color-blue-300-2: rgba(104, 201, 232, 0.2);

  --color-red-50: #fde6e8;
  --color-red-400: #e54558;
  --color-red-400-2: rgba(229, 69, 88, 0.2);

  --color-neutral-300: #f2f5fa;
  --color-neutral-400: #d5dbe5;
  --color-neutral-400-2: rgba(213, 219, 229, 0.2);

  --color-border-negative: var(--color-red-400);
  --color-border-warning: var(--color-yellow-500);
  --color-border-positive: var(--color-green-400);
  --color-border-primary: var(--color-neutral-400);

  --color-background-tertiary: var(--color-neutral-300);

  --color-light-purple: #6a6e8e;
  --color-light-gray: rgba(61, 61, 61, 0.8);
  --color-light-black: rgba(0, 0, 0, 0.8);
  --color-dark-gray: #3d3d3d;
  --color-black-2: #121212;
  --color-blue: #1a1f45;
  --color-blue-light: #00a6eb;
  --color-gray-1: #5e6266;
  --color-gray-2: #9caab4;
  --color-gray-3: #dce0e3;
  --color-gray-4: #dee5eb;
  --color-gray-5: #f8fafc;
  --color-gray-6: #697480;
  --color-gray-7: #fdffff;
  --color-light: #f6f9fc;
  --color-red: #ed2a24;
  --color-purple: #8273e3;
  --color-purple-darker: #4631e1;
  --color-red-light: #ff413b;
  --color-green: #00cf9d;
  --color-baby-blue: #1edcff;
  --color-baby-blue-lightned: #7decff;
  --color-dark-blue: var(--color-blue);
  --color-dark-blue-lightned: #43a0ce;
  --color-dark-pink: #ff3182;
  --color-pink: #ff78c6;
  --color-dark-pink-lightned: #f77eae;
  --color-primary-blue: #0094f6;
  --color-blue-2: #1f93ba;
  --color-blue-darkened: #0094f6;
  --color-gray-opacity: rgba(217, 217, 217, 0.53);
  --color-gray-4-opacity: rgba(222, 229, 235, 0.3);
  --color-gray-opacity-strong: rgba(220, 224, 227, 0.7);
  --color-gray: #666666;
  --color-primary-opacity-light: rgba(var(--color-primary-rgb), 0.2);

  --color-nav-stop-2: rgba(8, 123, 182, 0.6);
  --color-nav-stop-3: rgba(255, 49, 130, 0.6);
  --color-nav-stop-1: rgba(30, 220, 255, 0.6);

  --color-primary: var(--color-primary-blue);
  --color-primary-2: var(--color-blue-2);
  --color-primary-lightened: var(--color-primary-blue);
  --color-primary-lightened-opacity: #0094f625;
  --color-secondary: var(--color-primary-blue);
  --color-primary-rgb: 0, 148, 246;
  --color-primary-opacity: rgba(var(--color-primary-rgb), 0.08);

  --color-nav: var(--basic-white);
  --color-nav-active: var(--color-blue);
  --color-dark: var(--color-dark-blue);
  --color-footer: var(--color-light-purple);
  --color-footer-active: var(--basic-white);

  --color-featured: var(--color-dark-pink);

  --color-error: var(--color-red-light);

  --color-product-box-description: var(--color-dark-gray);

  --color-title: var(--color-black-2);

  --color-bg-footer: var(--color-blue);

  --color-button-card-list: var(--color-primary-blue);

  --color-text-card-list: var(--color-primary-blue);

  --color-table-header-rows: var(--color-dark-gray);

  --color-table-header-columns: var(--color-primary);

  --color-centered-accordion-titles: var(--color-primary);

  --color-theme: var(--color-primary);

  // GRADIENTS
  --pink-blue-gradient-lightned: linear-gradient(
    89.97deg,
    var(--color-baby-blue-lightned) 34%,
    var(--color-dark-blue-lightned) 57%,
    var(--color-dark-pink-lightned) 84%
  );

  --pink-blue-gradient: linear-gradient(
    89.97deg,
    var(--color-baby-blue) -25.82%,
    var(--color-dark-blue) 54.2%,
    var(--color-dark-pink) 120.78%
  );
  --color-gradient: linear-gradient(var(--color-blue), var(--color-blue));
  --nav-gradient-promotion: linear-gradient(
    to right,
    var(--color-blue-light),
    var(--color-purple-darker) 60%,
    var(--color-dark-pink) 90%
  );
  --nav-gradient: transparent;
  --nav-gradient-active: linear-gradient(var(--color-blue), var(--color-blue));
  --accent-gradient: linear-gradient(var(--basic-white), var(--basic-white));
  --theme-gradient: linear-gradient(87.5deg, var(--color-theme) 6.72%, var(--color-theme) 97.44%);
  --light-gradient: linear-gradient(87.5deg, var(--basic-white) 40%, var(--basic-white) 60%);
  --purple-gradient: linear-gradient(87.5deg, var(--color-purple) 6.72%, var(--color-purple) 97.44%);
  --pink-gradient: linear-gradient(87.5deg, var(--color-pink) 6.72%, var(--color-pink) 97.44%);

  //SPACING
  --base-spacing: 16px;

  //BORDER
  --border-radius-product-boxes: 12px;
  --button-border-radius: 6px;

  //Icons
  --check-mark: url('/resources/check-mark-lebara.svg');
}

.sakura-filled-button {
  --mdc-filled-button-container-shape: 8px;
  @apply hover:shadow-sakura-sm focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--pink-500];
}

.sakura-outlined-button {
  --mdc-outlined-button-container-shape: 8px;
  --mdc-outlined-button-label-text-color: var(--azure-500);
  --mat-outlined-button-state-layer-color: var(--blue-500);
  @apply focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--pink-500];
}

.sakura-text-button {
  --mdc-text-button-container-shape: 8px;
  --mdc-text-button-label-text-color: var(--azure-500);
  --mat-text-button-state-layer-color: var(--blue-500);
  @apply focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--pink-500];
}

.sakura-tonal-button {
  --mdc-filled-button-container-shape: 12px;
  --mdc-filled-button-container-color: var(--neutral-300);
  --mdc-filled-button-label-text-color: var(--azure-500);
  @apply focus:outline focus:outline-offset-2 focus:outline-1 focus:outline-[--pink-500];

  &:hover {
    --mdc-filled-button-container-color: var(--blue-500-4);
  }
}
